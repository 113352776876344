<template>
    <div class="page-not-found">
        <div class=" container py-5">
             <div class="text-center p-5">
                 <svg xmlns="http://www.w3.org/2000/svg" width="579.206" height="206.063" viewBox="0 0 579.206 206.063">
        <g id="Group_38" data-name="Group 38" transform="translate(-403.424 -251.514)">
          <g id="Group_37" data-name="Group 37" transform="translate(595.43 251.514)">
            <path id="Vector" d="M79.426,0a79.462,79.462,0,1,0,30.4,6.042A79.5,79.5,0,0,0,79.426,0Z" transform="translate(13.849 29.683)" fill="#fff" stroke="#231f20" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.045"></path>
            <path id="Vector-2" data-name="Vector" d="M7.337,5.448c0,3.009-1.642,5.448-3.669,5.448S0,8.457,0,5.448,1.643,0,3.669,0,7.337,2.439,7.337,5.448Z" transform="translate(131.23 77.724)" fill="#231f20"></path>
            <path id="Vector-3" data-name="Vector" d="M0,85.476l186.489-41.2L149.8,33.59S129.931.7,124.626.017s-101.7,19.839-101.7,19.839L16.815,66.187Z" fill="#263238"></path>
            <path id="Vector-4" data-name="Vector" d="M0,5.069S47.386-13.839,68.114,21.382" transform="translate(62.382 120.405)" fill="#fff"></path>
            <path id="Vector-5" data-name="Vector" d="M68.942,22.931a.78.78,0,0,1-.673-.4C48.107-11.7,1.577,6.373,1.118,6.556a.819.819,0,1,1-.6-1.526C1.011,4.847,48.78-13.725,69.63,21.68a.808.808,0,0,1-.275,1.083.826.826,0,0,1-.413.168Z" transform="translate(61.554 119.65)" fill="#231f20"></path>
            <path id="Vector-6" data-name="Vector" d="M21.079,49,0,15.734,92.144,0l6.328,31.879,9.355-29.361,47.142,9.294L145.385,49Z" transform="translate(17.747 148.029)" fill="#263238"></path>
            <path id="Vector-7" data-name="Vector" d="M0,0H8.575V31.957A4.032,4.032,0,0,1,4.54,35.986h-.5A4.032,4.032,0,0,1,0,31.957V0Z" transform="matrix(0.991, -0.137, 0.137, 0.991, 156.899, 124.891)" fill="#fff"></path>
            <path id="Vector-8" data-name="Vector" d="M9.147,37.837A4.812,4.812,0,0,1,4.393,33.7L.006,2.036a.763.763,0,0,1,.657-.855L9.147.006a.765.765,0,0,1,.566.153.717.717,0,0,1,.29.5l4.357,31.65a4.765,4.765,0,0,1-4.1,5.4l-.489.076a4.761,4.761,0,0,1-.627.046ZM1.627,2.585l4.28,30.9a3.285,3.285,0,0,0,3.684,2.793h.5A3.267,3.267,0,0,0,12.877,32.6L8.6,1.7Z" transform="translate(156.139 122.965)" fill="#231f20"></path>
            <path id="Vector-9" data-name="Vector" d="M0,0H13.833V31.255H0Z" transform="matrix(0.991, -0.137, 0.137, 0.991, 150.375, 96.952)" fill="#fff"></path>
            <path id="Vector-10" data-name="Vector" d="M5.054,34.39a.826.826,0,0,1-.459-.153.687.687,0,0,1-.29-.5L.01,2.755a.747.747,0,0,1,.138-.565.81.81,0,0,1,.5-.29L14.409.008a.748.748,0,0,1,.856.656l4.3,30.964a.763.763,0,0,1-.657.855L5.146,34.375ZM1.63,3.3,5.711,32.758,17.94,31.079,13.859,1.626Z" transform="translate(149.609 94.304)" fill="#231f20"></path>
            <path id="Vector-11" data-name="Vector" d="M4.891,0H8.942a4.887,4.887,0,0,1,4.891,4.884V49.476A4.887,4.887,0,0,1,8.942,54.36H4.891A4.887,4.887,0,0,1,0,49.476V4.884A4.887,4.887,0,0,1,4.891,0Z" transform="matrix(0.991, -0.137, 0.137, 0.991, 157.643, 149.36)" fill="#fff"></path>
            <path id="Vector-12" data-name="Vector" d="M11.765,56.013A5.653,5.653,0,0,1,6.17,51.145L.056,6.966A5.609,5.609,0,0,1,1.141,2.785,5.688,5.688,0,0,1,4.871.6L8.907.053A5.639,5.639,0,0,1,15.266,4.86L21.38,49.116a5.648,5.648,0,0,1-4.815,6.348l-4.035.565ZM9.686,1.518a3.537,3.537,0,0,0-.566,0l-4.035.549a4.083,4.083,0,0,0-2.721,1.6,4.009,4.009,0,0,0-.795,3.052L7.684,50.977a4.141,4.141,0,0,0,4.586,3.51l4.035-.565a4.122,4.122,0,0,0,3.516-4.578L13.706,5.089A4.125,4.125,0,0,0,9.64,1.533Z" transform="translate(157.496 147.351)" fill="#231f20"></path>
            <path id="Vector-13" data-name="Vector" d="M73.464,36.671A36.732,36.732,0,1,1,36.732,0,36.7,36.7,0,0,1,73.464,36.671Z" transform="translate(119.398 51.444)" fill="#fff"></path>
            <path id="Vector-14" data-name="Vector" d="M37.495,74.9a37.315,37.315,0,1,1,5.228-.351,39.366,39.366,0,0,1-5.228.351Zm0-73.358a36.394,36.394,0,0,0-4.968.336,36,36,0,1,0,5.029-.336Z" transform="translate(118.605 50.651)" fill="#231f20"></path>
            <path id="Vector-15" data-name="Vector" d="M59.676,29.789A29.838,29.838,0,1,1,29.838,0,29.813,29.813,0,0,1,59.676,29.789Z" transform="translate(126.292 58.327)" fill="#fff"></path>
            <path id="Vector-16" data-name="Vector" d="M30.572,61.042a30.6,30.6,0,1,1,4.2-.29,30.1,30.1,0,0,1-4.2.29Zm0-59.516a30.285,30.285,0,0,0-4.051.275,29.08,29.08,0,1,0,4.051-.275Z" transform="translate(125.543 57.626)" fill="#231f20"></path>
            <path id="Vector-17" data-name="Vector" d="M46.567,9.7a22.264,22.264,0,0,0-7.49-6.1C30.318-.987,20.276-.835,11.058,2.065,7.787,3.1,1.443,4.583,1.107,8.917a4.03,4.03,0,0,0,2.92,3.815,12.034,12.034,0,0,0,5.121.2,13.24,13.24,0,0,0-6.925,1.74C.251,16.059-.834,19.1.786,20.942a5.184,5.184,0,0,0,2.675,1.526,12.248,12.248,0,0,0,6.8-.092,31.741,31.741,0,0,0-6.955,2.182c-1.345.6-2.843,1.648-2.69,3.052S2.146,29.656,3.4,30.083a28.7,28.7,0,0,0,8.178,1.526,16.125,16.125,0,0,0-6.649,2.854C1.871,37.027,1.871,41.9,6.014,43.284a12.248,12.248,0,0,0,4.1.473c12.229,0,26.873-1.526,35.158-11.69a18.945,18.945,0,0,0,3.913-17.26A16.532,16.532,0,0,0,46.567,9.7Z" transform="translate(144.644 161.088)" fill="#fff"></path>
            <path id="Vector-18" data-name="Vector" d="M10.855,45.194a12.906,12.906,0,0,1-4.249-.5,5.268,5.268,0,0,1-3.638-4.014,6.648,6.648,0,0,1,2.293-6.1,11.408,11.408,0,0,1,3.3-1.908A28.486,28.486,0,0,1,3.977,31.49C1.943,30.788.919,29.75.69,28.438s.948-2.9,3.057-3.891l1.085-.458a8.1,8.1,0,0,1-.825-.2A5.765,5.765,0,0,1,.95,22.151a4.207,4.207,0,0,1-.9-3.3A6.1,6.1,0,0,1,2.524,14.78a7.463,7.463,0,0,1,1.529-.824,4.685,4.685,0,0,1-2.92-4.38C1.485,5.12,7,3.472,10.641,2.358l.978-.29C21.83-1.152,32-.588,40.22,3.594a22.921,22.921,0,0,1,7.75,6.379,17.494,17.494,0,0,1,2.767,5.311A19.646,19.646,0,0,1,46.685,33.26C38.018,43.836,23.008,45.209,10.9,45.225ZM9.4,24.15A30.209,30.209,0,0,0,4.45,25.874c-.718.32-2.354,1.19-2.232,2.32S3.5,29.72,4.481,30.04a27.723,27.723,0,0,0,7.964,1.526.749.749,0,0,1,.734.671.763.763,0,0,1-.566.824,15.911,15.911,0,0,0-6.359,2.7,5.047,5.047,0,0,0-1.788,4.67,3.726,3.726,0,0,0,2.629,2.854,11.023,11.023,0,0,0,3.837.427c11.785,0,26.353-1.312,34.607-11.4A18.138,18.138,0,0,0,49.33,15.757a15.984,15.984,0,0,0-2.537-4.838h0a21.209,21.209,0,0,0-7.23-5.952C31.69.923,21.953.389,12.124,3.441l-.994.305C7.89,4.723,2.968,6.2,2.692,9.622a3.313,3.313,0,0,0,2.415,3.052,7.425,7.425,0,0,0,2.736.336c.718-.076,1.422-.122,2.094-.168a.794.794,0,0,1,.81.7.779.779,0,0,1-.688.824c-.688,0-1.406.153-2.14.168A9.412,9.412,0,0,0,3.5,15.97a4.577,4.577,0,0,0-1.85,3.052,2.728,2.728,0,0,0,.55,2.121A4.45,4.45,0,0,0,4.5,22.395a11.759,11.759,0,0,0,4.586.305c.611-.153,1.238-.29,1.85-.412a.764.764,0,0,1,.887.565.778.778,0,0,1-.52.916,11.726,11.726,0,0,1-1.9.382Z" transform="translate(143.808 160.367)" fill="#231f20"></path>
            <path id="Vector-19" data-name="Vector" d="M4.876,0s18.618,27.927,7.353,28.324S0,25.775,0,25.775" transform="translate(101.101 88.696)" fill="#fff"></path>
            <path id="Vector-20" data-name="Vector" d="M11.028,29.833C1.582,29.833.221,27.254.038,26.781a.809.809,0,0,1,.5-1.007.8.8,0,0,1,.994.5h0s1.376,2.365,11.48,2a2.584,2.584,0,0,0,2.476-1.282C17.907,22.309,8.766,6.774,5.036,1.189A.795.795,0,0,1,6.351.3C7.772,2.44,20.108,21.363,16.9,27.773a4.1,4.1,0,0,1-3.821,2.152Z" transform="translate(100.284 88.072)" fill="#231f20"></path>
            <path id="Vector-21" data-name="Vector" d="M13.513,11.491c0,6.346-3.025,11.491-6.756,11.491S0,17.838,0,11.491,3.025,0,6.756,0,13.513,5.145,13.513,11.491Z" transform="translate(151.071 75.694)" fill="#d1d3d4"></path>
            <path id="Vector-22" data-name="Vector" d="M10.3,9.294c0,5.133-2.306,9.294-5.151,9.294S0,14.426,0,9.294,2.306,0,5.151,0,10.3,4.161,10.3,9.294Z" transform="translate(69.994 77.891)" fill="#231f20"></path>
          </g>
          <path id="Path_13" data-name="Path 13" d="M196.429,316.771H102.052v-42.61l94.377-112.184h45.155v114.6h23.4v40.194h-23.4v34.851H196.429Zm0-40.194V217.9l-49.868,58.681Z" transform="translate(301.372 105.956)" fill="#2a9976"></path>
          <path id="Path_15" data-name="Path 15" d="M420.493,316.771H326.116v-42.61l94.377-112.184h45.153v114.6h23.4v40.194h-23.4v34.851H420.493Zm0-40.194V217.9l-49.869,58.681Z" transform="translate(493.582 105.956)" fill="#2a9976"></path>
        </g>
      </svg>

<h1 class="text-center mt-4 mb-2">الصفحة المطلوبة غير متوفرة</h1>
<p class="text-center my-4">عذراُ ، ربما تكون الصفحة المطلوبة قد حذفت او تم تغيير الرابط</p>
<router-link to="/" class="btn btn-success">العودة الى الرئيسية</router-link>
             </div>
        </div>
        
    </div>
</template>

<script>

export default {
    name:"PageNotFound",
}
</script>
