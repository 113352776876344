<template>
    <div class="search-page">
        <div class="header-title">
    <div class="row justify-content-center align-items-center">
    
    <div class="col-sm-7">
      <div class=" text-center text-white mb-3 h4">
        يمكنك البحث من خلال كتابة ما تبحث عن في الحقل التالي
      </div>
      <div>
        <input type="text" class="form-control" id="search" v-model="searchData" placeholder="البحث...">
      </div>
    </div>
    </div>
        </div>
      <div class="container my-5">
         <div class="list-news-more">
                <div class="" v-if="searchData !=''">
                  <div class="item-news-liner d-flex p-2 bg-white mb-3" v-for="news in result.data "  :key="news.id" >
                    <div class="row g-3">
                      <div class="col-auto">
                        <div class="img-news-right">
                          <img
                            :src="news.image"
                            width="100%"
                            alt=""
                          />
                        </div>
                      </div>
                      <div class="col-7">
                        <div class="item-news-content-left">
                          <div class="title-news text-bold mb-2 mt-1">
                            <router-link :to="'/news-dt/' + news.slug" >{{ news.title }}</router-link>
                          </div>
                          <div
                            class="under-title-news d-flex align-items-center"
                          >
                            <div class="d-flex align-items-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="13.449"
                                height="14.944"
                                viewBox="0 0 13.449 14.944"
                              >
                                <path
                                  id="Icon_material-date-range"
                                  data-name="Icon material-date-range"
                                  d="M8.983,9.725H7.489v1.494H8.983Zm2.989,0H10.477v1.494h1.494Zm2.989,0H13.466v1.494H14.96Zm1.494-5.23h-.747V3H14.213V4.494H8.236V3H6.742V4.494H5.994A1.488,1.488,0,0,0,4.507,5.989L4.5,16.449a1.494,1.494,0,0,0,1.494,1.494h10.46a1.5,1.5,0,0,0,1.494-1.494V5.989A1.5,1.5,0,0,0,16.455,4.494Zm0,11.955H5.994V8.23h10.46Z"
                                  transform="translate(-4.5 -3)"
                                  fill="#9ca3a8"
                                />
                              </svg>
                              <span class="mx-2">{{ news.created_at }}</span>
                            </div>
                            <div class="d-flex align-items-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12.5"
                                height="12.5"
                                viewBox="0 0 12.5 12.5"
                              >
                                <path
                                  id="Icon_material-local-offer"
                                  data-name="Icon material-local-offer"
                                  d="M15.131,8.987,9.506,3.363A1.242,1.242,0,0,0,8.625,3H4.25A1.254,1.254,0,0,0,3,4.25V8.625a1.247,1.247,0,0,0,.369.888l5.625,5.625a1.242,1.242,0,0,0,.881.363,1.222,1.222,0,0,0,.881-.369l4.375-4.375a1.222,1.222,0,0,0,.369-.881,1.262,1.262,0,0,0-.369-.887ZM5.188,6.125a.937.937,0,1,1,.937-.937A.936.936,0,0,1,5.188,6.125Z"
                                  transform="translate(-3 -3)"
                                  fill="#e82026"
                                />
                              </svg>
                              <span class="mx-2">{{ news.category.title }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                 
                </div>
              </div>


      <div v-if="result.total == 0 || searchData == ''">
            لا يوجد نتائج
      </div>
  
    </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
    name:"SearchPage",
    data: function(){
        return {
            searchData: "",
            result:[],
        }
    }
,
watch: {
        searchData() {
            this.getDataSearch();
        }
    },
    methods: {          
        getDataSearch(){
            axios.get(`https://panel.almarshalnews.com/api/search?q=${this.searchData}`).then(res => this.result = res.data)
        }
    }
}
</script>

<style lang="scss" scoped>
.img-news-right{
  width: 150px;
  height: 120px;
  img{
    height: 120px !important;
  }
}
</style>