<template>
  <div class="footer-view">
    <section class="bg-footer py-5">
      <div class="container">
        <div class="row g-3">
          <div class="col-lg-4">
            <div class="text-start mb-3">
              <router-link class="" to="/">
                <img src="../assets/img/logo-white.svg" />
              </router-link>
            </div>
            <div class="text-white text-start text-about" v-for="ganeral in ganeralData.slice(0, 1)" :key="ganeral.id">
             {{ganeral.value}}
            </div>
          </div>
          <div class="col-lg-auto col-6">
            <div class="title-footer">
              <h4 class="mb-3 text-white">روابط تهمك</h4>
            </div>
            <ul class="list-footer">
              <li>
                <router-link to="/local" href="#">اخبار محلية</router-link>
              </li>
              <li>
                <router-link to="/news/سياسة" href="#">اخبار سياسية</router-link>
              </li>
              <li>
                <router-link to="/news/اقتصاد" href="#">اخبار اقتصادية</router-link>
              </li>
              <li>
                <router-link to="/news/رياضة" href="#">اخبار رياضية </router-link>
              </li>
            </ul>
          </div>
          <div class="col-lg-auto col-6">
            <div class="title-footer">
              <h4 class="mb-3 text-white">أخرى</h4>
            </div>
            <ul class="list-footer">
              <li>
                <router-link to="/contact" href="#">اتصل بنا</router-link>
              </li>
              <li>
                <router-link to="/blogs" href="#">اراء و مقالات</router-link>
              </li>
              <li>
                <router-link to="/photos">معرض الصور</router-link>
              </li>
              <li>
                <router-link to="/videos">الفيديو</router-link>
              </li>
            </ul>
          </div>
          <div class="col-lg-5 ">
            <a class="weatherwidget-io" href="https://forecast7.com/ar/15d5032d56/khartoum/" data-label_1="طقس" data-label_2="الخرطوم" data-font="Droid Arabic Kufi" data-days="3" data-textcolor="#FFFFFF" data-cloudcolor="#FFFFFF" data-cloudfill="#FFFFFF" > </a>
          </div>
        </div>
      </div>
    </section>
    <div class="bg-primary">
      <div class="container py-3">
        <div class="row align-items-center justify-content-between gy-3 g-lg-0">
          <div class="col-md-auto">
           <div class="text-white text-start text-about" v-for="ganeral in ganeralData.slice(7, 8)" :key="ganeral.id">
             {{ganeral.value}}
            </div>
          </div>
          <div class="col-md-auto">

            <router-link  v-for="page in pages"
                  :key="page.id"
                  :to="'/page-dt/' + page.slug" tag="a" class="mx-lg-2 mx-1 my-lg-0 my-2 text-white" >{{page.title}}</router-link>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterView",
   data: function () {
    return {
      pages: [],
      ganeralData: [],
    };
  },
  mounted() {
    this.fetchDataa().catch((error) => {
      console.error(error);
    });
    this.ganeral().catch((error) => {console.error(error);});
  },

  methods: {
    async fetchDataa() {
      this.pages = await fetch(
        `https://panel.almarshalnews.com/api/pages`
      )
        .then((res) => {
          return res.json();
        })
        .then((pages) => pages);
    },
    async ganeral() {
      this.ganeralData = await fetch(
        `https://panel.almarshalnews.com/api/general-settings`
      )
        .then((res) => {
          return res.json();
        })
        .then((ganeralData) => ganeralData);
    },

  },

};

!function(d,s,id){var js,fjs=d.getElementsByTagName(s)[0];if(!d.getElementById(id)){js=d.createElement(s);js.id=id;js.src='https://weatherwidget.io/js/widget.min.js';fjs.parentNode.insertBefore(js,fjs);}}(document,'script','weatherwidget-io-js');

</script>


<style lang="scss" scoped>
$mainColor: #ffad26;
.bg-footer {
  background-image: url("../assets/img/bg-footer.jpg");
  background-clip: #333;
  background-position: center;
  background-size: cover;
}
.text-about {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.6;
}
.title-footer {
  color: #fff;
}
.list-footer {
  list-style: none;
  padding: 0;
  margin: 0;
  color: #fff;
  li {
    margin-bottom: 8px;
    a {
      font-size: 16px;
      padding: 5px;
      margin: 0;
      margin-bottom: 7px;
      color: #fff;
      &:hover {
        color: $mainColor;
      }
    }
  }
}

</style>
