<template>
  <div class="videos-page">
    <div class="header-title">
      <h1>الفيديو</h1>
    </div>
    <div class="container my-5">
      <div class="row g-3" v-if=" state === 'loading'">
        <div class="col-lg-4 col-md-4">
              <div class="item-loading larg item1">
                    <div class="img-loading"> </div>
                    <div class="content-loading">
                    <div class="title-loading"></div>
                    <div class="sub-title-loading"></div>
                    </div>
                </div>
        </div>
        <div class="col-lg-4 col-md-4">
              <div class="item-loading larg item1">
                    <div class="img-loading"> </div>
                    <div class="content-loading">
                    <div class="title-loading"></div>
                    <div class="sub-title-loading"></div>
                    </div>
                </div>
        </div>
        <div class="col-lg-4 col-md-4">
              <div class="item-loading larg item1">
                    <div class="img-loading"> </div>
                    <div class="content-loading">
                    <div class="title-loading"></div>
                    <div class="sub-title-loading"></div>
                    </div>
                </div>
        </div>
        <div class="col-lg-4 col-md-4">
              <div class="item-loading larg item1">
                    <div class="img-loading"> </div>
                    <div class="content-loading">
                    <div class="title-loading"></div>
                    <div class="sub-title-loading"></div>
                    </div>
                </div>
        </div>
        <div class="col-lg-4 col-md-4">
              <div class="item-loading larg item1">
                    <div class="img-loading"> </div>
                    <div class="content-loading">
                    <div class="title-loading"></div>
                    <div class="sub-title-loading"></div>
                    </div>
                </div>
        </div>
        <div class="col-lg-4 col-md-4">
              <div class="item-loading larg item1">
                    <div class="img-loading"> </div>
                    <div class="content-loading">
                    <div class="title-loading"></div>
                    <div class="sub-title-loading"></div>
                    </div>
                </div>
        </div>
      </div>
      <div class="row g-3" v-if=" state === 'loaded'" uk-lightbox>
        <div class="col-lg-4 col-md-4" v-for="video in videos.data" :key="video.id">
          <a :href="video.video">
          <div class="news-slide larg-slid">
            <img :src="video.image" alt="" />
            <div class="content-news-slider">
              <div class="date-cat-top">
                <span class="date-slide d-inline-flex align-items-center">
                  <span class="mx-1 date-text">{{video.created_at}}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13.449"
                    height="14.944"
                    viewBox="0 0 13.449 14.944"
                  >
                    <path
                      id="Icon_material-date-range"
                      data-name="Icon material-date-range"
                      d="M8.983,9.725H7.489v1.494H8.983Zm2.989,0H10.477v1.494h1.494Zm2.989,0H13.466v1.494H14.96Zm1.494-5.23h-.747V3H14.213V4.494H8.236V3H6.742V4.494H5.994A1.488,1.488,0,0,0,4.507,5.989L4.5,16.449a1.494,1.494,0,0,0,1.494,1.494h10.46a1.5,1.5,0,0,0,1.494-1.494V5.989A1.5,1.5,0,0,0,16.455,4.494Zm0,11.955H5.994V8.23h10.46Z"
                      transform="translate(-4.5 -3)"
                      fill="#595b5d"
                    />
                  </svg>
                </span>
                <div class="play-btn">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="44.168"
                      height="43.338"
                      viewBox="0 0 44.168 43.338"
                    >
                      <g
                        id="Group_9391"
                        data-name="Group 9391"
                        transform="translate(0.271)"
                        opacity="0.74"
                      >
                        <g
                          id="Ellipse_16"
                          data-name="Ellipse 16"
                          transform="translate(-0.271 3)"
                          fill="#fff"
                          stroke="#707070"
                          stroke-width="1"
                        >
                          <circle cx="20" cy="20" r="20" stroke="none" />
                          <circle cx="20" cy="20" r="19.5" fill="none" />
                        </g>
                        <path
                          id="Icon_ionic-md-play-circle"
                          data-name="Icon ionic-md-play-circle"
                          d="M25.044,3.375A21.669,21.669,0,1,0,46.713,25.044,21.679,21.679,0,0,0,25.044,3.375ZM20.71,34.795v-19.5l13,9.751Z"
                          transform="translate(-2.816 -3.375)"
                          fill="#e82026"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
              </div>
              <div class="title-news-slide mb-3">
                <div class="text-center text-white">
                  
                    {{video.title}}
                 
                </div>
              </div>

              
            </div>
          </div>
   </a>

        </div>
      
      </div>
    <nav aria-label="Page navigation example px-0" style="padding: 25px 0 25px" v-if="videos.last_page != 1">
        <ul class="pagination justify-content-end px-0">
          <li class="page-item"  v-for="i in videos.last_page" :index="i" :key="i">
            <a @click="setPageNumber(i)"  class="page-link "  :class="{active:pageId==i}" >{{i}}</a></li>
         
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: "VideosView",
 data: function () {
    return {
      pageId : 1 ,
      videos: [],
      state:"loading",
  
    };
  },

created() {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchDataPages(this.$route.params.id)
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    );
  },
  mounted() {
    this.fetchDataPages().catch((error) => {
      console.error(error);
    });
  },

  methods: {
    setPageNumber(v){
      this.pageId = v;
      this.fetchDataPages();
    },
     async fetchDataPages() {
       this.state = "loading";
   //   alert(this.slug);
      this.videos = await fetch(
        `https://panel.almarshalnews.com/api/videos?page=${this.pageId}`
      )
        .then((res) => {
          return res.json();
        })
        .then((videos) => videos);
        this.state = "loaded";
    },
 
  },
};
</script>
