<template>
    <div class="caricature">
        <div class="header-title">
             <h1 id="title">كاركتير</h1>
        </div>
<div class="container my-5" uk-lightbox>
      <div class="card p-4 border-0">
        <div class="row g-3">
         <div class="col-lg-4">
<a :href="posts.image">
                  <div class="news-slide larg-slid uk-card-hover">
                    <img :src="posts.image" alt="" />
                    <div class="content-news-slider">
                      <div class="title-news-slide mb-3">
                        <div class="text-center text-white">
                          
                        {{ posts.title }}
                        </div>
                      </div>
                    </div>
                  </div>
</a>
                </div>
        </div>
      </div>

    </div>
    </div>
</template>

<script>

export default {
    name:"CaricatureView",
         data: function () {
    return {
      //      posts: JsonPosts,
      posts: [],
    };
  },
  mounted() {
    this.fetchData().catch((error) => {
      console.error(error);
    });
  },
  methods: {
    async fetchData() {
      this.posts = await fetch(
        `https://panel.almarshalnews.com/api/caricature`
      )
        .then((res) => {
          return res.json();
        })
        .then((posts) => posts);
    },
  },
}
</script>
