<template>
  <div class="blog-page">
    <div class="container">
      <h1>{{ title }}</h1>
      <div class="row">
        <div class="col-lg-8">
          <PostsView
            v-for="post in posts"
            v-bind:key="post.id"
            :name="post.name"
          />
        </div>
        <div class="col-lg-4"> 
          <SidebarView />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PostsView from "@/components/blog/PostsView.vue";
import SidebarView from "@/components/blog/SidebarView.vue";
//import JsonPosts from "../json/posts.json";
//import JsonPosts from "https://jsonplaceholder.typicode.com/comments?_page=1&_limit=5";
export default {
  data: function () {
    return {
      //      posts: JsonPosts,
      posts: [],
      currentPage: 0,
      perPage: 15,
      totalItems: 0,
    };
  },
  mounted() {
    this.fetchData().catch((error) => {
      console.error(error);
    });
  },
  methods: {
    async fetchData() {
      this.posts = await fetch(
        `https://jsonplaceholder.typicode.com/comments?_page=${this.currentPage}&_limit=${this.perPage}`
      )
        .then((res) => {
          this.totalItems = parseInt(res.headers.get("x-total-count"), 10);

          return res.json();
        })
        .then((posts) => posts);
    },
  },
  name: "BlogView",
  components: {
    PostsView,
    SidebarView,
  },
};
</script>
