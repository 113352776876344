<template>
    <div class="loading">
        <Transition>

        <div class="laoding-div" v-if="show">
           <img src="../assets/loader.gif" width="130" alt="">
        </div>
        </Transition>
    </div>
</template>

<script>
export default
{
    name:"LoadingComponent",
     data: function () {
    return {
        show:true,
    }
    
    },
        mounted() {
            this.showToggle();
        },
        methods: {
            showToggle(){
                setTimeout(() =>{
                    this.show = false
                },1300)
            }
        },

}
</script>

<style lang="scss">
    .laoding-div{
        position: fixed;
        background: #000;
        width: 100%;
        height: 100%;
        top: 0;
        display: flex;
        align-items:center;
        justify-content: center;
        color: #000;
        z-index: 999999;
    }
    /* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>