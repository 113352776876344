<template>
  <div class="header-salehh">
    <NewsHader />
    <section class="bg-white header">
      <div class="container">
        <nav class="navbar navbar-expand-lg navbar-light bg-white px-0">
          <div class="container-fluid">
            <router-link to="/" class="navbar-brand" >
              <img  src="../assets/img/logo.svg" width="100" />
            </router-link>
            <div>
                 <router-link to="/search" class="btn btn-outline-success  py-1 px-2 me-3 d-inline-block d-lg-none search-btn">
                  البحث
                   <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15.588"
                      height="15.588"
                      viewBox="0 0 15.588 15.588"
                    >
                      <g
                        id="Group_9336"
                        data-name="Group 9336"
                        transform="translate(1 1)"
                      >
                        <g
                          id="Icon_feather-search"
                          data-name="Icon feather-search"
                          transform="translate(0 0)"
                        >
                          <path
                            id="Path_722"
                            data-name="Path 722"
                            d="M16.21,10.355A5.855,5.855,0,1,1,10.355,4.5,5.855,5.855,0,0,1,16.21,10.355Z"
                            transform="translate(-4.5 -4.5)"
                            fill="none"
                            stroke="#000"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                          />
                          <path
                            id="Path_723"
                            data-name="Path 723"
                            d="M28.159,28.159l-3.184-3.184"
                            transform="translate(-14.985 -14.985)"
                            fill="none"
                            stroke="#000"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                          />
                        </g>
                      </g>
                    </svg>
                  </router-link>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            </div>
            
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
                <router-link tag="li" class="nav-item" to="/">
                  <a class="nav-link" href="#">الرئيسية</a>
                </router-link>

                <router-link
                  v-for="catc in categoriesa"
                  :key="catc.id"
                  tag="li"
                  class="nav-item"
                  :to="'/news/' + catc.slug"
                >
                  <a class="nav-link" href="#">{{ catc.title }}</a>
                </router-link>

                <router-link tag="li" class="nav-item" to="/blogs">
                  <a class="nav-link" href="#">آراء و مقالات</a>
                </router-link>
                 <router-link tag="li" class="nav-item" to="/contact">
                  <a class="nav-link" href="#">اتصل بنا</a>
                </router-link>
                <li class="nav-item dropdown">
                  <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    المزيد
                  </a>
                  <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <router-link tag="li" to="/videos">
                      <a class="dropdown-item" href="#">فيديو</a>
                    </router-link>
                    <router-link tag="li" to="/photos">
                      <a class="dropdown-item" href="#">اللبوم الصور</a>
                    </router-link>

                    
                  </ul>
                </li>
              </ul>
              <ul class="list-social-header align-items-center" v-if="ganeralData.length">
                <li class=" d-lg-block d-none">
                  <router-link to="/search" class="btn btn-outline-success  py-1 px-2 search-btn">
                  البحث
                   <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15.588"
                      height="15.588"
                      viewBox="0 0 15.588 15.588"
                    >
                      <g
                        id="Group_9336"
                        data-name="Group 9336"
                        transform="translate(1 1)"
                      >
                        <g
                          id="Icon_feather-search"
                          data-name="Icon feather-search"
                          transform="translate(0 0)"
                        >
                          <path
                            id="Path_722"
                            data-name="Path 722"
                            d="M16.21,10.355A5.855,5.855,0,1,1,10.355,4.5,5.855,5.855,0,0,1,16.21,10.355Z"
                            transform="translate(-4.5 -4.5)"
                            fill="none"
                            stroke="#000"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                          />
                          <path
                            id="Path_723"
                            data-name="Path 723"
                            d="M28.159,28.159l-3.184-3.184"
                            transform="translate(-14.985 -14.985)"
                            fill="none"
                            stroke="#000"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                          />
                        </g>
                      </g>
                    </svg>
                  </router-link>
                </li>
                <li >
                  <a :href="ganeral.value" target="_blank" v-for="ganeral in ganeralData.slice(4, 5)" :key="ganeral.id">
                  
                   <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                    >
                      <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                      <path
                        d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                      />
                    </svg>

                  </a>
                </li>
                <li>
                  <a :href="ganeral.value" target="_blank" v-for="ganeral in ganeralData.slice(9, 10)" :key="ganeral.id">
                  
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15.051"
                      height="12.224"
                      viewBox="0 0 15.051 12.224"
                    >
                      <path
                        id="Icon_awesome-twitter"
                        data-name="Icon awesome-twitter"
                        d="M13.5,4.737c.01.134.01.267.01.4a8.716,8.716,0,0,1-8.776,8.776A8.717,8.717,0,0,1,0,12.53a6.381,6.381,0,0,0,.745.038,6.178,6.178,0,0,0,3.83-1.318A3.09,3.09,0,0,1,1.69,9.111a3.89,3.89,0,0,0,.583.048,3.262,3.262,0,0,0,.812-.105A3.085,3.085,0,0,1,.611,6.026V5.988a3.107,3.107,0,0,0,1.394.392A3.089,3.089,0,0,1,1.05,2.254a8.768,8.768,0,0,0,6.36,3.228,3.482,3.482,0,0,1-.076-.707,3.088,3.088,0,0,1,5.338-2.111,6.073,6.073,0,0,0,1.958-.745,3.076,3.076,0,0,1-1.356,1.7,6.184,6.184,0,0,0,1.776-.477A6.631,6.631,0,0,1,13.5,4.737Z"
                        transform="translate(0 -1.69)"
                        fill="#202020"
                      />
                    </svg>

                  </a>

              
                </li>
                <li>
                  <a :href="ganeral.value" target="_blank" v-for="ganeral in ganeralData.slice(11, 12)" :key="ganeral.id">
                  
                  <svg data-v-1c51f0b7="" xmlns="http://www.w3.org/2000/svg" width="13.177" height="13.174" viewBox="0 0 13.177 13.174"><path data-v-1c51f0b7="" id="Icon_awesome-instagram" data-name="Icon awesome-instagram" d="M6.587,4.328A3.378,3.378,0,1,0,9.965,7.706,3.372,3.372,0,0,0,6.587,4.328Zm0,5.573a2.2,2.2,0,1,1,2.2-2.2,2.2,2.2,0,0,1-2.2,2.2Zm4.3-5.712A.788.788,0,1,1,10.1,3.4.786.786,0,0,1,10.891,4.19Zm2.237.8a3.9,3.9,0,0,0-1.064-2.76A3.924,3.924,0,0,0,9.3,1.165c-1.088-.062-4.348-.062-5.435,0a3.919,3.919,0,0,0-2.76,1.061A3.911,3.911,0,0,0,.044,4.987c-.062,1.088-.062,4.348,0,5.435a3.9,3.9,0,0,0,1.064,2.76,3.929,3.929,0,0,0,2.76,1.064c1.088.062,4.348.062,5.435,0a3.9,3.9,0,0,0,2.76-1.064,3.924,3.924,0,0,0,1.064-2.76c.062-1.088.062-4.345,0-5.432Zm-1.405,6.6a2.223,2.223,0,0,1-1.252,1.252,14.519,14.519,0,0,1-3.883.265A14.632,14.632,0,0,1,2.7,12.841a2.223,2.223,0,0,1-1.252-1.252,14.519,14.519,0,0,1-.265-3.883,14.632,14.632,0,0,1,.265-3.883A2.223,2.223,0,0,1,2.7,2.57a14.519,14.519,0,0,1,3.883-.265,14.632,14.632,0,0,1,3.883.265,2.223,2.223,0,0,1,1.252,1.252,14.519,14.519,0,0,1,.265,3.883A14.51,14.51,0,0,1,11.723,11.589Z" transform="translate(0.003 -1.119)" fill="#202020"></path></svg>

                  </a>
                </li>
                <li >
                   <a :href="ganeral.value" target="_blank" v-for="ganeral in ganeralData.slice(13, 14)" :key="ganeral.id">
                  
                   <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16.054"
                      height="11.288"
                      viewBox="0 0 16.054 11.288"
                    >
                      <path
                        id="Icon_awesome-youtube"
                        data-name="Icon awesome-youtube"
                        d="M16.244,4.016a2.017,2.017,0,0,0-1.419-1.429A47.672,47.672,0,0,0,8.552,2.25a47.672,47.672,0,0,0-6.272.338A2.017,2.017,0,0,0,.86,4.016,21.161,21.161,0,0,0,.525,7.905,21.161,21.161,0,0,0,.86,11.795,1.987,1.987,0,0,0,2.28,13.2a47.672,47.672,0,0,0,6.272.338,47.672,47.672,0,0,0,6.272-.338,1.987,1.987,0,0,0,1.419-1.406,21.161,21.161,0,0,0,.335-3.889,21.161,21.161,0,0,0-.335-3.889ZM6.91,10.292V5.518l4.2,2.387-4.2,2.387Z"
                        transform="translate(-0.525 -2.25)"
                        fill="#202020"
                      />
                    </svg>
                  </a>

                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </section>
  </div>
</template>

<script>
import NewsHader from "@/components/NewsHeader.vue"
export default {
  name: "HeaderSaleh",
  data: function () {
    return {
      categoriesa: [],
      ganeralData: {},
    };
  },
  components:{
    NewsHader,
  },
  mounted() {
    this.fetchDataa().catch((error) => {console.error(error);});
    this.ganeral().catch((error) => {console.error(error);});
  },

  methods: {
    async fetchDataa() {
      this.categoriesa = await fetch(
        `https://panel.almarshalnews.com/api/categories`
      )
        .then((res) => {
          return res.json();
        })
        .then((categoriesa) => categoriesa);
    },
     async ganeral() {
      this.ganeralData = await fetch(
        `https://panel.almarshalnews.com/api/general-settings`
      )
        .then((res) => {
          return res.json();
        })
        .then((ganeralData) => ganeralData);
    },
  },
};
</script>

<style lang="scss" scoped>

.header{
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 99;

}
$mainColor: #2A9976;
ul {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    a {
      text-align: center;
    }
  }
}
svg {
  height: 14px;
}
.list-social-header {
  display: flex;
  @media screen and (max-width:780px) {
        justify-content: center;
    padding: 10px;
    border-top: 1px solid #ccc;
  }
  li {
    padding: 5px 6px;
  }
}
nav {
  .navbar-nav .nav-link {
    font-size: 14px;
    font-weight: normal;
    &:hover {
      font-weight: bold;
      color: $mainColor;
    }
  }
}
.search-btn{
  border-radius: 20px;
  font-size: 14px;
  color: #000;
  transition: 0.3s ease-in-out;
  svg{
    path{
      stroke: #000;
      transition: 0.3s ease-in-out;
    }
  }
  &:hover{
     svg{
    path{
      stroke: #fff;
    }
  }
  }
}
</style>
