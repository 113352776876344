<template>
  <div class="photos-page">
    <div class="header-title">
      <h1>الصور</h1>
    </div>
    <div class="container my-5">
      <div class="row g-3" v-if=" state === 'loading'">
        <div class="col-lg-4 col-md-4">
              <div class="item-loading larg item1">
                    <div class="img-loading"> </div>
                    <div class="content-loading">
                    <div class="title-loading"></div>
                    <div class="sub-title-loading"></div>
                    </div>
                </div>
        </div>
        <div class="col-lg-4 col-md-4">
              <div class="item-loading larg item1">
                    <div class="img-loading"> </div>
                    <div class="content-loading">
                    <div class="title-loading"></div>
                    <div class="sub-title-loading"></div>
                    </div>
                </div>
        </div>
        <div class="col-lg-4 col-md-4">
              <div class="item-loading larg item1">
                    <div class="img-loading"> </div>
                    <div class="content-loading">
                    <div class="title-loading"></div>
                    <div class="sub-title-loading"></div>
                    </div>
                </div>
        </div>
        <div class="col-lg-4 col-md-4">
              <div class="item-loading larg item1">
                    <div class="img-loading"> </div>
                    <div class="content-loading">
                    <div class="title-loading"></div>
                    <div class="sub-title-loading"></div>
                    </div>
                </div>
        </div>
        <div class="col-lg-4 col-md-4">
              <div class="item-loading larg item1">
                    <div class="img-loading"> </div>
                    <div class="content-loading">
                    <div class="title-loading"></div>
                    <div class="sub-title-loading"></div>
                    </div>
                </div>
        </div>
        <div class="col-lg-4 col-md-4">
              <div class="item-loading larg item1">
                    <div class="img-loading"> </div>
                    <div class="content-loading">
                    <div class="title-loading"></div>
                    <div class="sub-title-loading"></div>
                    </div>
                </div>
        </div>
      </div>
      <div class="row g-4" v-if=" state === 'loaded'">
     <div class="col-lg-4" v-for="(item , index) in gallery.data" :key="item.id" :index="index" >
                  <router-link :to="'gallery/'+item.slug">
                  <div class="news-slide larg-slid">
                    <img :src="item.image" alt="" />
                    <div class="content-news-slider">
                      <div class="date-cat-top">
                        <span class="cat radus">{{item.media_count - 1}}</span>
                        <!-- <span class="cat radus">{{item.created_at}}</span> -->
                        <span class="date-slide d-inline-flex align-items-center">
                          <span class="mx-1 date-text">{{item.created_at}}</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="13.449"
                            height="14.944"
                            viewBox="0 0 13.449 14.944"
                          >
                            <path
                              id="Icon_material-date-range"
                              data-name="Icon material-date-range"
                              d="M8.983,9.725H7.489v1.494H8.983Zm2.989,0H10.477v1.494h1.494Zm2.989,0H13.466v1.494H14.96Zm1.494-5.23h-.747V3H14.213V4.494H8.236V3H6.742V4.494H5.994A1.488,1.488,0,0,0,4.507,5.989L4.5,16.449a1.494,1.494,0,0,0,1.494,1.494h10.46a1.5,1.5,0,0,0,1.494-1.494V5.989A1.5,1.5,0,0,0,16.455,4.494Zm0,11.955H5.994V8.23h10.46Z"
                              transform="translate(-4.5 -3)"
                              fill="#595b5d"
                            />
                          </svg>
                        </span>
                      </div>
                      <div class="title-news-album">
                        <div class="text-center">
                          <a :href="item.image">{{item.title}}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  </router-link>

                </div>
      </div>
 
          <nav aria-label="Page navigation example px-0" style="padding: 25px 0 25px" v-if="gallery.last_page != 1">
        <ul class="pagination justify-content-end px-0">
          <li class="page-item"  v-for="i in gallery.last_page" :index="i" :key="i">
            <a @click="setPageNumber(i)"  class="page-link "  :class="{active:pageId==i}" >{{i}}</a></li>
         
        </ul>
      </nav>

    </div>
  </div>
</template>

<script>
export default {
  name: "GalleryPage",
  
    data: function () {
    return {
      //      gallery: Jsongallery,
      gallery: [],
       pageId : 1 ,
       state: "loading",
    };
  },
  created() {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchDataPages(this.$route.params.id)
      },
      { immediate: true }
    );
  },
      mounted() {
    this.fetchDataPages().catch((error) => {
      console.error(error);
    });
  },
  methods: {
        setPageNumber(v){
      this.pageId = v;
      this.fetchDataPages();
    },
    async fetchDataPages() {
      this.state = "loading";
      this.gallery = await fetch(
        `https://panel.almarshalnews.com/api/galleries?page=${this.pageId}`
      )
        .then((res) => {
          return res.json();
        })
        .then((gallery) => gallery);
        this.state = "loaded";
    },
  },
};
</script>
