<template>
  <div class="posts-blog">
    <h1 v-bold="100">{{ name | shorttext(4) }}</h1>
  </div>
</template>

<script>
export default {
  name: "PostsView",
  props: ["name"],
};
</script>
