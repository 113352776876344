<template>
  <div class="local">
    <div class="header-title">
      <h1>محليات</h1>
    </div>
    <div class="container my-5">
      <div class="card p-4 border-0">
        <div class="row g-3">
       <div
              class="col-lg-3 col-md-3"
              v-for="(blog, index) in blogs"
              :key="blog.id"
              :index="index"
             
            >

              <router-link :to="'/news-dt/' + blog.slug" v-if="blog.is_active==1">
                <div class="news-slide small-slid">
                  <img :src="blog.image" alt="" />
                  <div class="content-news-slider">
                    <div class="date-cat-top">
                      <span class="date-slide d-inline-flex align-items-center">
                        <span class="mx-1 date-text">{{
                          blog.published_at
                        }}</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="13.449"
                          height="14.944"
                          viewBox="0 0 13.449 14.944"
                        >
                          <path
                            id="Icon_material-date-range"
                            data-name="Icon material-date-range"
                            d="M8.983,9.725H7.489v1.494H8.983Zm2.989,0H10.477v1.494h1.494Zm2.989,0H13.466v1.494H14.96Zm1.494-5.23h-.747V3H14.213V4.494H8.236V3H6.742V4.494H5.994A1.488,1.488,0,0,0,4.507,5.989L4.5,16.449a1.494,1.494,0,0,0,1.494,1.494h10.46a1.5,1.5,0,0,0,1.494-1.494V5.989A1.5,1.5,0,0,0,16.455,4.494Zm0,11.955H5.994V8.23h10.46Z"
                            transform="translate(-4.5 -3)"
                            fill="#595b5d"
                          />
                        </svg>
                      </span>
                    </div>
                    <div class="title-news-slide">
                      <div class="text-center">
                        <a href="#">{{ blog.title }}</a>
                      </div>
                      <div class="name-news text-center mt-1 text-primary">
                        {{ blog.author.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "LocalView", //this name page aboutView.vue
  components: {},
  data: function () {
    return {
      //      blogs: Jsonblogs,
      blogs: [],
      citylocal: [],
    };
  },
  mounted() {
    this.fetchData().catch((error) => {
      console.error(error);
    });
  },
  // isArabicChars(text) {
  //     var arregex = /[\u0600-\u06FF]/;
  //     return arregex.test(text);
  //   },
  methods: {
    async fetchData() {
      this.blogs = await fetch(
        `https://panel.almarshalnews.com/api/local-news-page`
      )
        .then((res) => {
          return res.json();
        })
        .then((blogs) => blogs.latest_news);
    },
  },
};
</script>
