<template>
  <div id="app" dir="rtl">
    <loading-component />
    <header-saleh />
    <router-view />
    <footer-view />
  </div>
</template>

<script>
import HeaderSaleh from "@/components/headerSaleh.vue";
import FooterView from "@/components/footerView.vue";
import LoadingComponent from "@/components/LoadingComponent.vue";

export default {
  name: "App",
  components: {
    HeaderSaleh,
    FooterView,
    LoadingComponent,
  },
  
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
