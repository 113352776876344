<template>
  <div class="help">
    <div class="container">
      <div class="row">
        <div class="col-4">
          <h1>This is an about pageccccccccccccccc</h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelpView",
};
</script>
