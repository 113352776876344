import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import "uikit/dist/css/uikit-rtl.min.css";
UIkit.use(Icons);
import VueMeta from "vue-meta";

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
});

import "bootstrap";
import "bootstrap/dist/css/bootstrap.rtl.min.css";

import "./scss/main.scss";
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

Vue.directive("bold", {
  bind: function (el, b) {
    el.style.fontSize = b + "px";
  },
});

Vue.filter("shorttext", function (v, lengthpost) {
  return v.substring(0, lengthpost) + " ...";
});

Vue.mixin({
  methods: {
    globalHelper: function () {
      alert("Hello world");
    },
  },
});
