<template>
  <div class="sidbar-app">
    <h2>here sidebar</h2>
  </div>
</template>

<script>
export default {
  name: "SidebarView",
};
</script>
