import Vue from "vue";
import VueRouter from "vue-router";
import HelpView from "../views/HelpView.vue";
import BlogView from "../views/BlogView.vue";
import VideosView from "../views/VideosView.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import CaricatureView from "../pages/CaricatureView.vue";
import SearchPage from "../pages/SearchPage.vue";

import GalleryPage from "../pages/GalleryPage.vue";
import LocalView from "../views/LocalView.vue";

import PageNotFound from "../pages/PageNotFound.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ "../views/HomeView.vue"),
  },

  { path: '/404', component: PageNotFound },  
  { path: '*', redirect: '/404' },  
  {
    path: "/blogs",
    name: "blogs",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/help",
    name: "help",
    component: HelpView,
  },
  {
    path: "/blog",
    name: "blog",
    component: BlogView,
  },
  {
    path: "/contact",
    name: "contact",
    component: () =>  
    import(/* webpackChunkName: "contact" */ "../pages/ContactPage.vue"),
  },

  {
    path: "/search",
    name: "search",
    component: SearchPage,
  },
  
  {
    path: "/videos",
    name: "videos",
    component: VideosView,
  },
  {
    path: "/photos",
    name: "photos",
    component: GalleryPage,
  },
  {
    path: "/gallery/:id",
    component: () => import(/* webpackChunkName: /tags/:id */ "@/pages/GalleryDt.vue"),
  },
  {
    path: "/tags/:id",
    component: () => import(/* webpackChunkName: /tags/:id */ "@/pages/TagsPage.vue"),
  },
  {
    path: "/caricature",
    name: "caricature",
    component: CaricatureView,
  },
  
  {
    path: "/news/:id",
    component: () => import(/* webpackChunkName: /news/:id */ "@/views/NewsCat.vue"),
  },
  {
    path: "/articles-dt/:id",
    component: () => import(/* webpackChunkName: tr/articles-dt/:idue */ "@/views/ArticlesDt.vue"),
  },
  {
    path: "/privacypolicy",
    name: "privacypolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/local",
    name: "localView",
    component: LocalView,
  },
  
  {
    path: "/news-dt/:id",
    component: () => import(/* webpackChunkName: /news-dt/:id */ "@/views/NewsDt.vue"),
  },
  {
    path: "/page-dt/:id",
    component: () => import(/* webpackChunkName: /page-dt/:id */ "@/pages/PageDt.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
