<template>
    <div class="news-header" v-if="newsheader.length">
 
        <div class="row g-0 align-items-center m-0">
            <div class="col-auto h-100">
                <div class="news-had bg-danger text-white h-100 py-2 px-3">
                    الاخبار العاجلة
                   
                </div>
            </div>
            <div class="col-10  h-100">
                <VueSlickCarousel v-bind="settings"  class="custom-slider-news  h-100">
                <div class="p-2 news-item" v-for="newsc in newsheader" :key="newsc.id">
                    {{newsc.title}}
                </div>
                
                </VueSlickCarousel>
            </div>
        </div>
    
    </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";

import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
    name: "NewsHader",
    
    components: { VueSlickCarousel },
    data() {
    return {
      newsheader: [],
      settings: {
        infinite: true,
        slidesToShow: 1,
        speed: 3000,
        autoplay: true,
        pauseOnFocus:true,
        pauseOnHover:true,
        rtl:true,
        vertical:true,
        verticalSwiping:true,
        
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      },
    };
  },
  
  mounted() {
    this.fetchDataheader().catch((error) => {
      console.error(error);
    });
  },
  methods: {
    async fetchDataheader() {
      this.newsheader = await fetch(
        `https://panel.almarshalnews.com/api/breaking-news`
      )
        .then((res) => {
          return res.json();
        })
        .then((newsheader) => newsheader);
    },
  },
}
</script>

<style lang="scss" >
  .custom-slider-news .slick-arrow{
        display: none !important;
    }
    .news-header{
        position: fixed;
        width: 100%;
        bottom: 0;
        z-index: 99999;
        background: #fff;
        box-shadow: 0 0 5px #00000029;
    }
    .news-had{
      font-size: 16px;
      font-weight: bold;
      
    }
    .news-item{
      font-size: 16px;
    }
</style>
